import React, { useEffect, useState } from 'react';
import { Sidenav, Nav, Icon } from 'rsuite';
import 'rsuite/styles/index.less';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import CheckImage from '../../../assets/images/check.png'
import UnCheckImage from '../../../assets/images/uncheck.png'
import BlueCheckImage from '../../../assets/images/blueCheck.png'

//API-Service
import { dimensionListService } from '../../../services/dimensionService'
import { indicatorListService } from '../../../services/indicatorService'
import { userQuestionService } from '../../../services/userQ&AService'
import { getAssesmentListService } from '../../../services/submissionService'

// Utility-Service
import { getToken, getUser } from "../../../utility/AuthService";
import Cookies from 'js-cookie'

//Custom component
import Question from '../../pages/QuestionPage'
import SubmissionEndModal from '../../components/modal/SubmissionEndModal'
import UploadedDocumentsModal from "../../components/modal/UploadedDocumentsModal";
import ShowPdfModal from "../../components/modal/ShowPdfModal";

//redux
import { selectLanguageUse } from '../../../redux/slices/language.Slice'


function Sidebar(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [allDimension, setAllDimension] = useState([])
    const [allIndicator, setAllIndicator] = useState([])
    const [firstIndicator, setFirstIndicator] = useState([])
    const [submissionID, setSubmissionID] = useState([])
    const [limit, setLimit] = useState(10)
    const [indicatorID, setIndicatorID] = useState()
    const [currentIndicator, setCurrentIndicator] = useState([])
    const [expandDimension, setExpandDimension] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [assesmentList, setAssesmentList] = useState([]);
    const [upDocBtn, setUpDocBtn] = useState(false);
    const [submissionId, setSubmissionId] = useState()
    const [uploadedDocumentsModal, setUploadedDocumentsModal] = useState(false);
    const [pdfItem, setPdfItem] = useState();
    const [showPdfModalShow, setShowPdfModalShow] = useState(false);
    const handleModalShow = () => setModalShow(!modalShow);
    const { id } = useParams()
    const authToken = getToken()
    const user = getUser()
    const languageUse = useSelector(selectLanguageUse || user?.language)
    const viewPage = location.pathname.includes('view')
    // const currentLanguageCode = Cookies.get("i18next") || "en";

    const decryptedSubId = CryptoJS.AES.decrypt(id, 'mySecretKey').toString(CryptoJS.enc.Utf8);

    const handleShowPdfModalShow = () => setShowPdfModalShow(!showPdfModalShow);

    const UploadedDocumentsModaltoggle = (id) => {
        setSubmissionId(id)
        setUploadedDocumentsModal(!uploadedDocumentsModal)
    };

    /**
     * @function getAssesmentList
     * @param ()
     * @description get Assesment List
     */
    const getAssesmentList = async () => {
        try {
            const result = await getAssesmentListService({
                authToken,
                limit: 10,
                offset: 0
            });
            const value = result?.data?.data?.list
            setAssesmentList(value)
        } catch (error) {
            setAssesmentList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getAssesmentList()
    }, []);

    /**
    * @function getQuestions
    * @param ()
    * @description handle API to get the Questions
    */
    const getQuestions = async (indicatorID, name, requiredText, requiredText_Malay) => {
        // { languageUse === 'English' ? props.setIndicatorName(requiredText) : props.setIndicatorName_Malay(requiredText) }

        props.setIndicatorName(requiredText)
        props.setIndicatorName_Malay(requiredText_Malay)

        props?.setSelectedLanguage(languageUse)
        props.setIndicator_ID(indicatorID)
        setCurrentIndicator(name)
        props.setCurrentIndicator(name)
        setIndicatorID(indicatorID)
    };

    /**
    * @function dimensionList
    * @param ()
    * @description handle API to get the dimensions
    */
    const dimensionList = async () => {
        try {
            const result = await dimensionListService({
                authToken,
                filter: 'Active',
                limit: 20,
                offset: 0
            });
            setAllDimension(result?.data?.data?.list)
        } catch (error) {
            setAllDimension([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        dimensionList()

    }, [])

    /**
        * @function getIndicator
        * @param ()
        * @description handle API to get the indicators
    */
    const getIndicator = async () => {
        try {
            const result = await indicatorListService({
                authToken,
                filter: 'Active',
                limit: 20,
                offset: 0,
                submissionId: decryptedSubId,
            });
            setAllIndicator(result?.data?.data?.list)
            setFirstIndicator(result?.data?.data?.list?.filter(item_Indi => item_Indi.dimension?._id === allDimension[0]?._id))
        } catch (error) {
            setAllIndicator([])
            setFirstIndicator([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getIndicator()

    }, [props.changeIndicatorName, allDimension]);


    useEffect(() => {

        if (indicatorID) {
            const nextIndicatorIndex = allIndicator?.findIndex(item => item._id === indicatorID);

            const nextIndicator = allIndicator[nextIndicatorIndex + 1]?._id;
            const nextIndicatorName = allIndicator[nextIndicatorIndex + 1]?.name;
            const nextIndicatorRequiredText = allIndicator[nextIndicatorIndex + 1]?.requiredText;
            const nextIndicatorRequiredText_Malay = allIndicator[nextIndicatorIndex + 1]?.requiredText_Malay;

            const currIndicators = allIndicator?.filter(item_Indi => item_Indi.dimension?._id === allIndicator[nextIndicatorIndex]?.dimension?._id)

            if (currIndicators?.findIndex(item => item._id === indicatorID) === currIndicators.length - 1) {
                const nextDimension = allDimension?.findIndex(item_Indi => item_Indi?._id === allIndicator[nextIndicatorIndex]?.dimension?._id) + 1

                if (nextDimension < allDimension?.length) {

                    if (expandDimension?.length > 0 && !expandDimension?.includes(allDimension[nextDimension]?._id)) {
                        const temp = [...expandDimension, allDimension[nextDimension]?._id]
                        setExpandDimension(temp);

                    }

                    const nextDimIndicators = allIndicator?.filter(item_Indi => item_Indi.dimension?._id === allDimension[nextDimension]?._id)

                    const nextDimIndicatorsID = nextDimIndicators[0]?._id
                    const nextDimIndicatorName = nextDimIndicators[0]?.name;
                    const nextDimIndicatorRequiredText = nextDimIndicators[0]?.requiredText;
                    const nextDimIndicatorRequiredText_Malay = nextDimIndicators[0]?.requiredText_Malay;

                    // getQuestions(nextDimIndicatorsID, nextDimIndicatorName, languageUse === 'English' ? nextDimIndicatorRequiredText : nextDimIndicatorRequiredText_Malay)
                    getQuestions(nextDimIndicatorsID, nextDimIndicatorName, nextDimIndicatorRequiredText, nextDimIndicatorRequiredText_Malay)
                }
            } else {
                // getQuestions(nextIndicator, nextIndicatorName, languageUse === 'English' ? nextIndicatorRequiredText : nextIndicatorRequiredText_Malay)

                getQuestions(nextIndicator, nextIndicatorName, nextIndicatorRequiredText, nextIndicatorRequiredText_Malay)
            }
        }
    }, [props.changeIndicatorName]);

    useEffect(() => {
        if (allDimension?.length > 0) {

            const filteredDimension = allDimension.map(item => {
                if (item.isOnSummary) {
                    return item._id
                }
            }).filter(item => item !== undefined)

            if (allIndicator?.length > 0) {
                const filteredIndicator = allIndicator.filter(item => filteredDimension.includes(item.dimension._id))

                if (filteredIndicator?.length > 0 && Object.values(filteredIndicator).every(item => item?.isCompleted === 'true') && assesmentList?.length > 0 && assesmentList?.find(item => item?._id === decryptedSubId)?.isDraft) {
                    if (!viewPage) {
                        setModalShow(true)
                    }
                    // navigate(`/uploadDocuments/${decryptedSubId}`)
                    setUpDocBtn(true)
                }
            }
        }
    }, [allDimension, allIndicator]);

    return (
        <div>
            <>
                {allDimension?.length > 0 ? <>
                    <Sidenav openKeys={expandDimension} onOpenChange={(openKeys, event) => {

                        setExpandDimension(openKeys)
                    }}>
                        <Sidenav.Body>
                            {allDimension?.length > 0 && allDimension?.map((item, index) => (
                                <Nav>
                                    <Nav.Menu className='assesment-dropdown' eventKey={item._id} title={languageUse === 'English' ? item.name : item.name_Malay} key={item._id}>

                                        {allIndicator?.length > 0 && allIndicator?.filter(item_Indi => item_Indi.dimension?._id === item._id).map((item_Indi, index_Indi) => (
                                            <Nav.Item eventKey={item_Indi._id} key={item_Indi._id} onClick={() => getQuestions(item_Indi._id, item_Indi.name, item_Indi.requiredText, item_Indi.requiredText_Malay)}>
                                                <img src={(item.name === 'Demographic' && item.isOnSummary) ? CheckImage : item_Indi.isCompleted === 'true' ? CheckImage : currentIndicator === item_Indi.name ? BlueCheckImage : UnCheckImage} className="check" />{' '}

                                                {/* {item_Indi.name}
                                                    {item_Indi.name_Malay} */}
                                                {languageUse === 'English' ? item_Indi.name : item_Indi.name_Malay}

                                                {/* {item_Indi.isCompleted} */}
                                            </Nav.Item>
                                        ))}
                                    </Nav.Menu>
                                </Nav>
                            ))}
                        </Sidenav.Body>
                    </Sidenav>
                    {!viewPage && <button className='btn btn-outline-primary' onClick={() => (upDocBtn ? navigate(`/uploadDocuments/${decryptedSubId}`) : toast.error('All Questions need to be answered before upload documents'))}>{languageUse === 'English' ? 'Upload Supporting Documents' : 'Muatnaik dokumen sokongan'}</button>}

                    {viewPage && <button className='btn btn-outline-primary' onClick={() => UploadedDocumentsModaltoggle(decryptedSubId)}>{languageUse === 'English' ? 'Upload Supporting Documents' : 'Muatnaik dokumen sokongan'}</button>}
                </> : <></>}
                <SubmissionEndModal show={modalShow} handleClose={handleModalShow} submission_id={decryptedSubId} questionPage={true} decryptedSubId={decryptedSubId} />

                <UploadedDocumentsModal modal={uploadedDocumentsModal} toggle={UploadedDocumentsModaltoggle} handleShowPdfModalShow={handleShowPdfModalShow} submissionId={submissionId} setPdfItem={setPdfItem} />
                <ShowPdfModal show={showPdfModalShow} handleClose={handleShowPdfModalShow} pdfItem={pdfItem} />
            </>

        </div>

    )
};

export default Sidebar